module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kaira Luchi","short_name":"Kaira Luchi","description":"IT and Business Consulting","lang":"en","start_url":"/","background_color":"#247fe1","theme_color":"#247fe1","display":"standalone","icon":"src/images/small-logo.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
